<template>
  <div id="loading-bg">
    <div class="loading" v-if="loading">
      <loader :loading-bar="false" />
      <!-- <div class="effect-1 effects" />
      <div class="effect-2 effects" />
      <div class="effect-3 effects" /> -->
    </div>
    <div v-if="expired" class="div">
      <h1>
        Your session has Expired <br />
        please re-login to continue
      </h1>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  name: "TokenStaging",
  components: { Loader },
  data() {
    return {
      loading: true,
      expired: false
    };
  },
  methods: {
  },
  created() {
    localStorage.setItem('hasRefreshed', 'false')
    const {action, request} = this.$route.query
    if(action && request){
    localStorage.setItem("action", action)
    localStorage.setItem("request", request)
    }
    const { linchpin } = this.$route.query;
    this.$_getLinchpin().then(data => {
      this.$store.dispatch("getAppSettings").then(() => {
          const localAction = localStorage.getItem("action")
          const localRequest = localStorage.getItem("request")
        if (linchpin) {
          const parsedLinchpin = data.data;

          localStorage.setItem("token", parsedLinchpin.token);
          localStorage.setItem("linchpin", JSON.stringify(parsedLinchpin));
          this.$store.commit("UPDATE_STATE_FROM_LOCALSTORAGE", null, {
            root: true
          });
          const { user, org } = parsedLinchpin;

          if (user.isEmployee) {
            if(localRequest && localAction) {
            this.$router.push({ name: `Ess${localAction}`, params: {id: `${localRequest}`} });
            return
            }
            window.location.assign("/ess/dashboard");
          } else if (!user.roles.humanar && !user.isRoot) {
            window.location.replace(
              `${process.env.VUE_APP_CLOUDENLY}sign-in/?domain=${org.alias}`
            );
          } else {
            if(localRequest && localAction) {
              this.$router.push({ name: `${localAction}`, params: {id: `${localRequest}`} });
              return
            }
            window.location.assign("/admin/dashboard");
            this.loading = false;
          }
        } else if (localStorage.getItem("linchpin")) {
          const users = JSON.parse(localStorage.getItem("linchpin"));
          if (users.user.isEmployee) {
            if(localRequest && localAction) {
              this.$router.push({ name: `Ess${localAction}`, params: {id: `${localRequest}`} });
              return
            }
            this.$router.push({ name: "EssDashboard" });
          } else if (!users.user.roles.humanar && !users.user.isRoot) {
            window.location.replace(
              `${process.env.VUE_APP_CLOUDENLY}sign-in/?domain=${users.org.alias}`
            );
          } else {
            if(localRequest && localAction) {
              this.$router.push({ name: `${localAction}`, params: {id: `${localRequest}`} });
              return
            }
            this.$router.push({ name: "AdminDashboard" });
          }
        } else {
          this.loading = false;
          this.expired = true;
        }
      });
    });
  }
};
</script>

<style scoped>
.div {
  font-size: 1.7rem;
  text-align: center;
  font-weight: 700;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(50% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(121, 97, 249, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(121, 97, 249, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
